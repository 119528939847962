import {Injectable, RendererFactory2, ViewEncapsulation, Inject, PLATFORM_ID} from '@angular/core'
import {DOCUMENT, isPlatformBrowser} from '@angular/common'

@Injectable({providedIn: 'root'})
export class CanonicalLinkService {
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId,
  ) {}

  // Attach canonical link into the head element
  addCanonicalLink(url: string): void {
    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: 'canonical link renderer',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {},
      })

      const link = renderer.createElement('link')
      const head = this.document.head

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.')
      }

      renderer.setAttribute(link, 'rel', 'canonical')
      renderer.setAttribute(link, 'href', url)

      renderer.appendChild(head, link)
    } catch (e) {
      console.error('Error within canonicalService : ', e)
    }
  }
  removeCanonicalLink(): void {
    // Only in Browser remove previous canonical link during page routing
    if (isPlatformBrowser(this.platformId)) {
      const element: HTMLLinkElement = this.document.querySelector("link[rel='canonical']") || null
      if (element) {
        element.remove()
      }
    }
  }
}
